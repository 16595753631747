/* eslint-disable react/jsx-wrap-multilines */
import React from 'react'

import { Layout } from '@leshen/gatsby-theme-contentful'
import { graphql } from 'gatsby'

import { Main } from '@leshen/ui'

import {
  mapRichTextHero,
  mapSections,
} from '@leshen/gatsby-theme-contentful/utils/determiners'

import ZipPackageSection from '../components/ZipPackageSection'

const DynamicZipPackagesCart = ({ data }) => {
  const {
    contentfulPage: { sections, hero, spanish },
  } = data

  const heroOptions = {
    mapper: mapRichTextHero,
    isImageCritical: true,
    spanish,
  }

  const sectionOptions = {
    spanish,
  }

  const ctaBar =
    sections[0]?.__typename === 'ContentfulCtaBar' ? sections[0] : null // eslint-disable-line no-underscore-dangle

  const possiblyModifiedSections = ctaBar ? sections.slice(1) : sections

  return (
    <Layout
      data={data}
      main={
        <Main>
          {/* Pass the hero to Leshens map so it behaves like normal when coming from Contentful */}
          {mapSections(hero, heroOptions)}
          {ctaBar && mapSections(ctaBar, sectionOptions)}

          <div id="loadingScrollTarget" />

          {/* AvailablePlans will show the package cards based on what is available from the Availability form getting used from the Hero */}
          <ZipPackageSection />

          {/* Render the rest of the sections from Contentful like normal */}
          {possiblyModifiedSections &&
            possiblyModifiedSections.map((section) =>
              mapSections(section, sectionOptions)
            )}
        </Main>
      }
    />
  )
}

export default DynamicZipPackagesCart

export const query = graphql`
  query DynamicZipPackagesCartQuery($id: String!) {
    contentfulPage(id: { eq: $id }) {
      ...LeshenPage
    }
    site {
      ...LeshenMetadata
    }
  }
`
