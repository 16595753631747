import { css } from '@emotion/core'
import styled from '@emotion/styled'
import React, { useEffect } from 'react'

import {
  Brandy,
  LeshenPhoneCTA,
  PackageCard,
  useMapi,
  useUser,
} from '@leshen/gatsby-theme-leshen'
import {
  Columns,
  LinkButton,
  Stack,
  Typography,
  VariableContent,
  scrollTo,
} from '@leshen/ui'

import _get from 'lodash.get'

import useAvailablePlans from '../hooks/useAvailablePlans'
import useHasMounted from '../hooks/useHasMounted'

import getUrlParameter from '../utils/getUrlParameter'
import zipLogic from '../utils/zipLogic'

import Spinner from './Spinner'

const ZipPackageSection = ({ spanish }) => {
  const { userData, userLocation, setUserData, setZipLocation } = useUser()

  const mapiData = useMapi()

  const phoneNumber = _get(mapiData, 'rotatedNumber')

  // Store the userData in localStorage for persistance.
  const { availablePlans, setAvailablePlans } = useAvailablePlans(userData)

  /**
   * Check that userData is not null, which is it's initial state.
   * userData being set means it's been called at least once, and
   * the loading variable shows that it's currently loading.
   *
   * Important Note: We cannot just check for userData.loading
   * because that alone coming back false does not mean we want
   * to show the loading state. On initial page load, userData should
   * be null and we don't want to show a loading state then.
   *
   * This should be reworked in a big way, as there is a little too much
   * indirection for my taste, but there are a lot of moving parts that
   * aren't all accessible from this codebase so it will take some work.
   */
  const arePackagesLoading = userData && userData.loading

  useEffect(() => {
    /**
     * Commonly on page reload, `userData` will be null, and
     * we don't want to override localStorage with that data.
     * This does assume that `userData` will never be wiped
     * on purpose with the intent to wipe localStorage.
     * `null` is meant to be a starting point, and `userData`
     * should just be set to an empty object if the intent is
     * to clear `availablePlans`.
     */

    // this statement checks if aff_unique3 exists in the url.
    // that param is something that consumer may send to us and if so, we want to run the
    // zip logic on that zipcode (if userData doesn't already exist)
    const zipcode = getUrlParameter('aff_unique3') || userLocation?.zipCode

    if (zipcode && zipcode.length === 5 && !userData) {
      ;(async () => {
        const packageData = await zipLogic(zipcode, spanish)

        setZipLocation(zipcode)

        setUserData({
          loading: false,
          zipCheck: false,
          centurylinkService: packageData?.centurylinkService || false,
          getQFiberPackages: packageData?.getQFiberPackages || false,
          getQFiberDisclaimer: packageData?.getQFiberDisclaimer || null,
          typeOfService: packageData?.typeOfService,
        })
      })()
    }

    if (!userData && availablePlans) {
      return
    }
    setAvailablePlans(userData)
  }, [
    userData,
    setUserData,
    availablePlans,
    setAvailablePlans,
    userLocation,
    setZipLocation,
    spanish,
  ])

  /**
   * This should be handled in the callback for the availability
   * lookup, but because that is handled through shadowing, and not
   * through passing down props from here, it is not feasible. I
   * decided it would be best to have the scrollTo and it's target
   * in the same file after considering all this.
   */
  useEffect(() => {
    if (availablePlans && availablePlans?.getQFiberPackages?.length >= 1) {
      scrollTo(`#loadingScrollTarget`)
    }
  }, [availablePlans])

  /**
   * Server-side rendering check to not have mismatching data
   * on the server, which causes a bad rehydration to layout
   * content incorrectly in some situations when using local storage.
   */
  const hasMounted = useHasMounted()
  if (!hasMounted) {
    return null
  }

  const { typeOfService = 'QF' } = availablePlans || {}

  const packagesWereFound = availablePlans?.getQFiberPackages?.length >= 1
  const showQfSection =
    typeOfService === 'QF' &&
    !arePackagesLoading &&
    userData &&
    availablePlans?.zipCheck
  const showCtlSection =
    typeOfService === 'CTL' &&
    !arePackagesLoading &&
    userData &&
    availablePlans?.zipCheck
  const showOoaSection =
    typeOfService === 'OOA' &&
    !availablePlans.loading &&
    !packagesWereFound &&
    availablePlans?.zipCheck

  return (
    <>
      {arePackagesLoading && (
        <VariableContent
          className="packages"
          alignMainContent="center"
          mainContent={
            <>
              <Typography variant="h2">
                {spanish
                  ? 'Buscando paquetes en tu zona.'
                  : 'Searching packages in your area.'}
              </Typography>
              <Spinner />
            </>
          }
        />
      )}

      {/* Set up the section(s) that show the users packages or out of area message below the Hero */}
      {showQfSection && (
        <VariableContent
          className="packages"
          alignMainContent="center"
          mainContent={
            <div>
              <Typography variant="h2">
                {spanish
                  ? 'Planes y precios de internet de Quantum Fiber'
                  : 'Find the Right Speed for the Right Price'}
              </Typography>
              <Typography>
                {spanish
                  ? 'Puede ser difícil encontrar el plan de Internet adecuado. Así que te lo ponemos fácil, reuniendo todas las velocidades de Internet disponibles en tu área en un solo lugar. Haz tu elección.'
                  : 'It can be difficult to find the right internet plan. So we’ve made it easy for you, gathering all the available internet speeds in your area in one place. Make your choice.'}
              </Typography>
              {userLocation.city && userLocation.zipCode && (
                <Typography>
                  {spanish
                    ? 'Mostrando el mejor plan(es) para'
                    : 'Showing best plan(s) for'}{' '}
                  {userLocation.city}, {/* eslint-disable-next-line */}
                  {userLocation.zipCode}{' '}
                  <StyledButtonLink
                    type="button"
                    onClick={() => {
                      scrollTo('h1')
                    }}
                  >
                    {spanish ? 'cambiar' : 'change'}
                  </StyledButtonLink>
                </Typography>
              )}
            </div>
          }
        >
          <Stack spacing="xxl" alignMainContent="center">
            <Columns>
              {availablePlans.getQFiberPackages.map((data) => (
                <PackageCard
                  label={data.label}
                  packageData={{
                    ...data.brandy,
                  }}
                  content={
                    // Disabled lint line due to Gatsby api named variable
                    // eslint-disable-next-line no-underscore-dangle
                    <LeshenPhoneCTA color="primary">
                      {spanish ? 'Llama al' : 'Call'}
                    </LeshenPhoneCTA>
                  }
                  key={data.label}
                  modal={
                    <span>
                      <a
                        href={spanish ? '/es/terminos-y-condiciones' : '/terms'}
                      >
                        {spanish ? 'Ver detalles' : 'Offer Details'}
                      </a>
                    </span>
                  }
                />
              ))}
            </Columns>
            <Brandy
              symbol={availablePlans.getQFiberDisclaimer.symbol}
              text={availablePlans.getQFiberDisclaimer.text}
              variant="legal"
            />
          </Stack>
        </VariableContent>
      )}

      {showCtlSection && (
        <VariableContent
          alignMainContent="center"
          mainContent={
            <div>
              <Typography variant="h6">
                <strong>
                  {spanish
                    ? 'Lo sentimos. Parece que no estás en el área de servicio de Internet de Quantum Fiber.'
                    : 'We’re sorry, Quantum Fiber isn’t available there.'}
                </strong>
              </Typography>
              <Typography variant="h2">
                Good news! We found other plans in your area
              </Typography>
              <StyledButtonsContainer>
                {availablePlans.centurylinkService && (
                  <>
                    <LinkButton
                      variant="feature"
                      className="view-plans-button"
                      to={`https://centurylinkquote.com/?getqfiberZip=${userLocation.zipCode}`}
                    >
                      {spanish ? 'Ver Planes' : 'View Plans'}
                    </LinkButton>
                    <LinkButton variant="feature" to={`tel:${phoneNumber}`}>
                      {spanish ? 'Llama al ' : 'Call '}{' '}
                      <span className="inline-block">{phoneNumber}</span>
                      {spanish ? ' para más opciones' : ' for more options'}
                    </LinkButton>
                  </>
                )}
              </StyledButtonsContainer>
            </div>
          }
        />
      )}

      {showOoaSection && (
        <VariableContent
          mainContent={
            <>
              <Typography variant="h2">
                {spanish
                  ? 'Estamos teniendo problemas para encontrar opciones de servicio para tu área.'
                  : "We're having trouble locating service options for your area."}
              </Typography>
              <p>
                {spanish
                  ? '¡Llámanos y uno de nuestros especialistas en Internet puede ayudar a conectarte!'
                  : 'Give us a call and one of our Internet specialists can help get you connected!'}
              </p>
            </>
          }
          alignMainContent="center"
        />
      )}
    </>
  )
}

export default ZipPackageSection

const StyledButtonLink = styled.button(
  ({ theme }) => css`
    color: ${theme.colors.primary.base.hex};
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    display: inline;
    font-weight: bold;
  `
)

const StyledButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .view-plans-button {
    margin-right: 16px;
  }
`
